import { reactive } from 'vue';
import { createStore } from 'vuex';
import EventService from '@/services/EventService';

export const GStore = reactive({ flashMessage: '', event: null });

export default createStore({
  state: {
    user: 'Adam Jahr',
    events: [],
    event: {},
    eventTotal: 0,
    perPage: 10,
  },
  mutations: {
    ADD_EVENT(state, event) {
      state.events.push(event);
    },
    SET_EVENTS(state, events) {
      state.events = events;
    },
    SET_EVENT(state, event) {
      state.event = event;
    },
    SET_EVENT_TOTAL(state, eventTotal) {
      state.eventTotal = eventTotal;
    },
  },
  actions: {
    createEvent({ commit }, event) {
      EventService.postEvent(event)
        .then(() => {
          commit('ADD_EVENT', event);
        })
        .catch((error) => {
          throw error;
        });
    },
    fetchEvents({ commit, state }, { page }) {
      return EventService.getEvents(state.perPage, page)
        .then((response) => {
          commit(
            'SET_EVENT_TOTAL',
            parseInt(response.headers['x-total-count'])
          );
          commit('SET_EVENTS', response.data);
        })
        .catch((error) => {
          throw error;
        });
    },
    fetchEvent({ commit, state }, id) {
      const existingEvent = state.events.find((event) => event.id === id);
      if (existingEvent) {
        commit('SET_EVENT', existingEvent);
        return existingEvent;
      } else {
        return EventService.getEvent(id)
          .then((response) => {
            commit('SET_EVENT', response.data);
            return response.data;
          })
          .catch((error) => {
            throw error;
          });
      }
    },
  },
  modules: {},
});
